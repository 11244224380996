export default {
  base: {
    funKey: 'base',
    name: '基础功能',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title',
      method: 'GET'
    }]
  }
}
