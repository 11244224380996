export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/ask_leave',
      method: 'GET'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '附件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取附件'
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载附件'
  }
}
  