export default {
    get: {
      funKey: 'get',
      name: '查看',
      apis: [{
        path: '/basis/worker_auth',
        method: 'GET'
      }]
    },
    add_all: {
      funKey: 'add_all',
      name: '新增',
      apis: [{
        path: '/basis/worker_auth',
        method: 'POST'
      },
      {
        path: '/basis/worker',
        method: 'GET'
      }]
    },
    update_all: {
      funKey: 'update_all',
      name: '修改',
      apis: [{
        path: '/basis/worker_auth/{id}',
        method: 'PUT'
      },
      {
        path: '/basis/worker',
        method: 'GET'
      }]
    },
    del: {
      funKey: 'del',
      name: '删除',
      apis: [{
        path: '/basis/worker_auth/{id}',
        method: 'DELETE'
      }]
    }
  }