export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  idcard_file: {
    funKey: 'idcard_file',
    name: '查看扫描件'
  },
  file_update: {
    funKey: 'file_update',
    name: '修改扫描件',
    apis: [{
      path: '/basis/worker/{id}',
      method: 'PUT'
    }]
  }
}