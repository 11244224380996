export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/wage',
      method: 'GET'
    }]
  },
  input: {
    funKey: 'input',
    name: '导入',
    apis: [{
      path: '/basis/wage',
      method: 'POST'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  outPut: {
    funKey: 'outPut',
    name: '导出'
  },
  outPutP: {
    funKey: 'outPutP',
    name: '导出模版'
  },
  clear: {
    funKey: 'clear',
    name: '清空',
    apis: [{
      path: '/basis/wage/clear',
      method: 'POST'
    }]
  }
}