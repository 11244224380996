export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/worker/detail',
      method: 'GET'
    },
    {
      path: '/basis/position',
      method: 'GET'
    },
    {
      path: '/basis/org_type',
      method: 'GET'
    },
    {
      path: '/basis/org/tree',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title',
      method: 'GET'
    }]
  },
  history: {
    funKey: 'history',
    name: '历史数据',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/org_type',
      method: 'GET'
    },
    {
      path: '/basis/position',
      method: 'GET'
    },
    {
      path: '/basis/org/tree',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/worker',
      method: 'POST'
    },
    {
      path: '/basis/org_type',
      method: 'GET'
    },
    {
      path: '/basis/position',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/worker/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/org_type',
      method: 'GET'
    },
    {
      path: '/basis/position',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/worker/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/org_type',
      method: 'GET'
    }]
  },
  // getWorkerEdit: {
  //   funKey: 'getWorkerEdit',
  //   name: '查看信息变更记录',
  //   apis: [
  //     {path: '/basis/modify_apply/my', method: 'GET'}
  //   ]
  // },
  // applyWorkerAdd: {
  //   funKey: 'applyWorkerAdd',
  //   name: '申请新增',
  //   apis: [{
  //     path: '/basis/modify_apply',
  //     method: 'POST'
  //   }]
  // },
  // applyWorkerEdit: {
  //   funKey: 'applyWorkerEdit',
  //   name: '申请修改',
  //   apis: [{
  //     path: '/basis/modify_apply',
  //     method: 'POST'
  //   }]
  // },
  sort: {
    funKey: 'sort',
    name: '默认排序设置',
    apis: [{
      path: '/basis/worker/{id}',
      method: 'PUT'
    }]
  },
  // applyWorkerDel: {
  //   funKey: 'applyWorkerDel',
  //   name: '申请删除',
  //   apis: [{
  //     path: '/basis/modify_apply',
  //     method: 'POST'
  //   }]
  // },
  resume: {
    funKey: 'resume',
    name: '简历'
  },
  output: {
    funKey: 'output',
    name: '导出'
  },
  allinfo: {
    funKey: 'allinfo',
    name: '明细',
    apis: [{
      path: '/basis/worker_job_title',
      method: 'GET'
    },
    {
      path: '/basis/his_work',
      method: 'GET'
    },
    {
      path: '/basis/agreement',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate',
      method: 'GET'
    },
    {
      path: '/basis/org/all_tree',
      method: 'GET'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    }]
  }
}
