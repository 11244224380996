export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/agreement',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '处理',
    apis: [{
      path: '/basis/agreement/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    }]
  }
}