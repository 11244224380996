export default {
  get: {
    funKey: 'base',
    name: '基础功能',
    apis: [{
      path: '/basis/data/Appraisal',
      method: 'GET'
    },
    {
      path: '/basis/data/ref/query/get_my_appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalQuestion',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalQuestion',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalQuestion/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalQuestion/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/data/AppraisalGroup',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalGroup',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalGroup/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalGroup/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/data/ref/query/get_appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalGroupDetail',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalGroupDetail/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalGroupDetail/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/data/ref/query/get_appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalResult',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalResult/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalResult/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/data/ref/query/get_appraisal_result_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalResultDetail',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalResultDetail/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalResultDetail/{id}',
      method: 'DELETE'
    }]
  }
}