export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/data/Appraisal',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/data/Appraisal',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/data/Appraisal/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/data/Appraisal/{id}',
      method: 'DELETE'
    }]
  },
  result: {
    funKey: 'result',
    name: '结果',
    apis: [{
      path: '/basis/data/ref/query/get_appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/ref/query/get_appraisal_result_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalGroupDetail/{id}',
      method: 'PUT'
    }]
  },
  otherAction: {
    funKey: 'otherAction',
    name: '其他操作',
    apis: [{
      path: '/basis/data/ref/query/get_appraisal_result_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/ref/query/get_appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalGroupDetail/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/Appraisal/{id}',
      method: 'PUT'
    }]
  },
  ready: {
    funKey: 'ready',
    name: '设置',
    apis: [{
      path: '/basis/data/Appraisal',
      method: 'GET'
    },
    {
      path: '/basis/data/Appraisal/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalQuestion',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalQuestion',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalQuestion/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalQuestion/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/data/AppraisalGroup',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalGroup',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalGroup/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalGroup/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/data/ref/query/get_appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/data/AppraisalGroupDetail',
      method: 'POST'
    },
    {
      path: '/basis/data/AppraisalGroupDetail/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/data/AppraisalGroupDetail/{id}',
      method: 'DELETE'
    }]
  }
}